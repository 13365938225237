@import "~bootstrap/scss/bootstrap";


// *{
//   scroll-behavior: smooth;
// }

body {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0 !important;
}

html{
  overflow-x: hidden !important;
  // scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

h1,h2,h3,h4,h5,h6 {
  font-family: "Lato", sans-serif;
  font-weight: 800;
}

p,a,button{
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

